import API from '@/api/request'

function get_env_all_data(url, method, params){
    return API({
        url: url,
        method: method,
        params: params

    })
}

export default get_env_all_data

