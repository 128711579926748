import axios from 'axios'


var host = window.location.host

if(process.env.NODE_ENV === "development"){
    host = `${process.env.VUE_APP_API_HOST}`
}
export default axios.create({
    //baseURL: `${process.env.VUE_APP_API_HOST}`, //请求后端数据的基本地址，自定义
    baseURL: `http://${host}`,
    timeout: 6000                   //请求超时设置，单位ms
})