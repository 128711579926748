import { createStore } from 'vuex'
import getters  from "@/store/getters";

import user from '../store/module/user'


const store = createStore({
    modules: {
        user
    },
    getters


})

export default store