const state = () => {
    return {
        name: "chengjianguo",
        age: 100,
        env: []
    }
}


const mutations = {
    test(state, data){
        state.env = data
    }
}



const actions = {
    demo({commit}, data){
        commit('test', data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}