<template>
<!--  <h1>hello world{{name}}</h1>-->
  <div id="app">
    <el-dialog v-model="dialogFormVisible" title="渠道录入" width="80%">
      <el-form :model="form">
        <el-form-item label="EnvName: " label-width="110px">
          <el-input v-model="form.EnvName" autocomplete="off" />
        </el-form-item>
        <el-form-item label="AccountDbhost: " label-width="110px">
          <el-input v-model="form.AccountDbhost" autocomplete="off" />
        </el-form-item>
        <el-form-item label="AccountDbport: " label-width="110px">
          <el-input v-model="form.AccountDbport" autocomplete="off" />
        </el-form-item>
        <el-form-item label="AccountDbname: " label-width="110px">
          <el-input v-model="form.AccountDbname" autocomplete="off" />
        </el-form-item>
        <el-form-item label="AccountDbuser: " label-width="110px">
          <el-input v-model="form.AccountDbuser" autocomplete="off" />
        </el-form-item>
        <el-form-item label="AccountDbpass: " label-width="110px">
          <el-input v-model="form.AccountDbpass" autocomplete="off" />
        </el-form-item>

      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="ConfirmMethod">
          Confirm
        </el-button>
      </span>
      </template>
    </el-dialog>
  </div>


</template>

<script setup>
  import {ref , reactive} from 'vue'
  import { ElMessage } from 'element-plus'
  const dialogFormVisible = ref(true)

  const name = ref("chengjianguo")
  name.value = "sss"
  const form = reactive({
    env: '',
    region: '',
    date1: '',
    date2: '',
    delivery: false,
    type: [],
    resource: '',
    desc: '',
  })
  const ConfirmMethod = ()=>{

    dialogFormVisible.value = false
    ElMessage({
      message: 'create env success',
      type: 'success',
      duration: 1000
    })
  }
</script>

<style scoped>



#app{
  font-family: initial;
  font-weight: bold;
}





</style>