import API from '@/api/request'

function get_online_info(url, method, data){
    return API({
        url: url,
        method: method,
        data: data

    })
}

export default get_online_info
