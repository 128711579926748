import API from '@/api/request'

function getenv(url, method){
    return API({
        url: url,
        method: method,


    })
}

export default getenv

