<template>
  <div class="hello">
    <div id="head">
      <div id="left">
        <img :src="icon"/>
        <h2 style="color: #FFFFFF">仙境传说·新启航在线数据</h2>
      </div>
      <div id="control">
        <el-icon size="25" color="#FFFFFF" @click="setting"><Setting /></el-icon>
        <span style="font-weight: bold; color: #FFFFFF">setting</span>
      </div>
    </div>

    <div id="metadata">
      <div class="radius" :style="{
          borderRadius: 'base'
            ? `var(--el-border-radius-base)`
            : '',
        }">
        <h3>当前时间：{{getCurrentDate}}</h3>
        <h3>总在线数：<span class="env_all_data_info">{{env_all_data.all_online_num}}</span></h3>
        <h3>总创角数：<span class="env_all_data_info">{{env_all_data.all_role_num}}</span></h3>
        <h3>总注册账号数：<span class="env_all_data_info">{{env_all_data.all_account_num}}</span></h3>
      </div>
    </div>
    <div id="change_env" style="height: 1000px">
      <div id="table">
        <el-tabs type="border-card" @tab-click="change_env"  >

            <el-tab-pane v-for='single_env in env'  :key="single_env" :label='single_env'   >

                <el-table :data="tableData" border style="width: 100%;color: black" height=1000  :header-row-style="ColumnStyle" :cell-style="serviceModuleStyle">
                  <el-table-column prop="wid" label="大区ID"   />
                  <el-table-column prop="wname" label="区名称"  />
                  <el-table-column prop="sid" label="服ID"  />
                  <el-table-column prop="sname" label="服名称"   />
                  <el-table-column prop="server_status" label="服状态"   />
                  <el-table-column prop="online_num" label="在线人数"  />
                  <el-table-column prop="online_change" label="在线1min变化"   />
                  <el-table-column prop="queue_num" label="排队人数"  />
                  <el-table-column prop="create_role_num" label="创角人数" />
                </el-table>
            </el-tab-pane>

        </el-tabs>
      </div>






    </div>


  </div>
</template>

<script>

/*
执行时机，created --> computed ---> mountd
computed 已经把store里的数据加载进来了，所有mountd 后页面能直接获取计算属性
 */

import {mapGetters} from 'vuex'
import get_online_info from '@/api/get_online_info'
import get_env_all_data from '@/api/get_env_all_data'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data(){
    return {
        date: '',
        tableData: [],
        icon: require("@/assets/火箭.png"),
        head_background_image: require("@/assets/index-banner.6583093b.png"),
        reload: '',
        env_all_data: {
          all_account_num: '',
          all_online_num: '',
          all_role_num: ''
        }
    }
  },


  methods: {
    change_env(e) {
      //获取单服在线表格数据
      get_online_info('/get_online_info', 'post', {
        env: e.props.label
      }).then(data => {
        this.tableData = data.data
      }).catch(err => {
        console.log(err)
      })

      //获取总环境在线数据
      get_env_all_data('/get_env_online', 'get', {
        env: e.props.label
      }).then(data=>{
        this.env_all_data.all_account_num = data.data.data.all_account_num
        this.env_all_data.all_role_num = data.data.data.all_role_num
        this.env_all_data.all_online_num = data.data.data.all_online_num

      }).catch(err => {
        console.log(err)
      })



    },

    setting() {
      this.$router.push({path: "/setting"})
    },
    serviceModuleStyle({row, columnIndex}) {
      if (row.online_change.indexOf("+") ===0 && columnIndex === 6) {
        return {
          "color": "red"
        }
      }else if (row.online_change.indexOf("+") === -1 && columnIndex === 6){
        return {
          "color": "green"
        }
      }else if (row.server_status==="对外" && columnIndex === 4){
        return {
          "color": "#ffa3e3",
        }
      }
    },
    ColumnStyle(){
      return {
         "color": "black"
      }

    },
    autoreload(){
      if(this.$route.path === "/setting"){
         clearInterval(this.reload)
      }else{
        location.reload()

      }
    }

  },

  computed: {
    ...mapGetters([
        'env'

    ]),
    getCurrentDate(){
      return this.date
    }
  },


  mounted() {
    setInterval( () => {
          this.date = new Date().format("yyyy-MM-dd hh:mm:ss");
        }, 1000
    )
    this.reload = setInterval(this.autoreload, 60000)



    get_online_info('/get_online_info', 'post', {
      env: this.env[0]
    }).then(data=>{
      this.tableData=data.data

    }).catch(err => {
      console.log(err)
    })

    //获取总环境在线数据
    get_env_all_data('/get_env_online', 'get', {
      env: this.env[0]
    }).then(data=>{
      this.env_all_data.all_account_num = data.data.data.all_account_num
      this.env_all_data.all_role_num = data.data.data.all_role_num
      this.env_all_data.all_online_num = data.data.data.all_online_num
    }).catch(err => {
      console.log(err)
    })





  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.testtest>div{
  background-color: aqua;

}
.hello>#head>#left{
    font-size: medium;
    width: 320px;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.hello>#head>#control {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*text-align: right;*/
  align-items: center;
  margin-right: 20px;
}

.hello>#head {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-image: url("../assets/index-banner.6583093b.png")
}


.hello>#head img{
    width: 45px;
    height: 55px;

}

.radius {
  border: 1px solid var(--el-border-color);
  border-radius: 0;
  margin-top: 2px;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  flex-direction: column;
  justify-items: center;

}


.radius>h3{
  margin: 6px;
}

#metadata{
  margin-bottom: 10px;
  margin-top: 10px;
}

.el-tabs--border-card>.el-tabs__content{
   padding: 0!important;
}

.el-tabs__nav-scroll{
  background-color: #f5ffd3!important;

}
.el-tabs__item.is-top.is-active{
  color: green!important;
  font-size: x-large;
}

.env_all_data_info{
  color: #67c23a;
}


</style>
