import {createRouter, createWebHistory} from 'vue-router'
import HelloWorld from '@/components/HelloWorld'
import ControlSetting from '@/components/ControlSetting'
const router = createRouter({
    routes: [
        {
            path: "/",
            redirect: {
                query: {
                    sign: "1b62a5b84a42e5c74804f8f97e33a5a4ba65409b310b8cc992f9c354037c9be9",
                },
                path: "/index"
            }
        },

        {
            path: "/index",
            component: HelloWorld,
            name: "index"
        },
        {
            path: "/setting",
            component: ControlSetting,
            name: "ControlSetting"
        }
    ],
    history: createWebHistory()


})

export default router