import router from '@/router/router'
import {ElMessage} from 'element-plus'
import Getenv from "@/api/getenv";
import store from '@/store'


//获取所有env 信息
// async function fetch_data(){


    /*
    管理页面sign：4c153f63a02026e13a3e042ab50582e6cb111a5affdc366cc4fd8b58d00d1e16
    普通浏览页面sign: 1b62a5b84a42e5c74804f8f97e33a5a4ba65409b310b8cc992f9c354037c9be9
 */

/*
    如果写成async function fetch_data(){
        await Getenv(...)
        router.beforeEach(...)
    }
    fetch_data()
    这样写是不行的，因为代码执行的时候会等待await 执行完，有段时间才能到beforeEach，代码以为还没有路由守卫，直接全部路由都放行了，所以async 函数
    要和下方写法一样。让代码直接就找到beforeEach，走正常的路由守卫流程



    1. 这里beforeEach 里传入async 函数，因为想在路由没跳转前，就将接口返回数据存入store中，因为接口请求为异步，生产环境可能接口很耗时
    next(false) 代表就让路由不变，啥都不干。不会陷入路由死循环

    2. beforeEach里传async 函数，beforeEach 里具体每个逻辑if 都要有跳转路由 next的操作，不然会报Invalid navigation guard 错误
    如果不传async 函数的，可以在每个if里自由的next,也可以不写next


 */

router.beforeEach( async(to,from,next)=>{


    if(to.path === "/setting"){
        if(from.query.sign !== "4c153f63a02026e13a3e042ab50582e6cb111a5affdc366cc4fd8b58d00d1e16"){
             ElMessage({
                type: "error",
                message: "this sign not allowed"
            })
            next(false)
        }else{

            next()
        }
    }else{
        if(to.query.sign !== "4c153f63a02026e13a3e042ab50582e6cb111a5affdc366cc4fd8b58d00d1e16" &&  to.query.sign !== "1b62a5b84a42e5c74804f8f97e33a5a4ba65409b310b8cc992f9c354037c9be9"){
            ElMessage({
                type: "error",
                message: "sign is invalid"
            })
            next(false)
        }else{
            await Getenv('/get_env', 'get').then(
                (data)=>{
                    store.dispatch('user/demo', data.data.env)
                }
            ).catch((err)=>{
                console.log(err)
            })
            next()
        }

    }
})


// fetch_data()

